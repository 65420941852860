import {
  ActionButton,
  Callout,
  Checkbox,
  ColorPicker,
  DefaultButton,
  DirectionalHint,
  getTheme,
  IBasePickerSuggestionsProps,
  IChoiceGroupOption,
  IColor,
  IIconProps,
  IObjectWithKey,
  IPersonaProps,
  ISelection,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  NormalPeoplePicker,
  Persona,
  // Selection,
  Separator,
  Stack,
  ValidationState,
} from '@fluentui/react';
import _ from 'lodash';
import React, {useEffect, useRef, useState} from 'react';
import {Field, Form} from 'react-final-form';
import {uPrinceTheme} from '../../../../theme';
import {DropDownAdaptater, TextFieldFF} from '../../../shared/fluentFinalForm';
import {HumanResource, LabourPersonDTO, PERSON_ROLE_WORKER_ID, WorkSchedule} from '../../../types/humanResource';
import HistoryBar from '../../../shared/historyBar/historyBar';
import {useTranslation} from 'react-i18next';
import TaxonomyField from './taxonomy/taxonomy';
import {CompetenceTaxonomy} from '../../../types/competency';
import {TeamMember} from '../../cuProjects/teams/modal/component';
import OrganisationGrid from '../../addressBook/documentPane/grid/component';
import {ActionButtonState} from '../../../types/corporateProductCatalogue';
import {CertificationTaxonomy} from '../../../types/certification';
import CSWeeklyGrid from '../../../shared/csWeekGrid/component';
import LabourHistoryGrid from './historyGrid/component';
import CustomDropdown from '../../../shared/customDropdown/customDropdown';
import {RESOURCE_TYPE} from '../../../types/purchaseOrder';
import client from '../../../api';
import {messageService} from '../../../services/messageService';
import {useDispatch} from 'react-redux';
import {saveCuHrAtrr} from '../../../reducers/humanResourceReducer';
import {CAB_COMPANY_EP, HR_PERSON_FILTER_EP} from '../../../shared/endpoints';
import SearchableDropdown from '../../../shared/searchableDropdown/searchableDropdown';
import {mergeStyles} from '@fluentui/react/lib/Styling';
import {AddressBookItem, CABFilter} from '../../../types/addressBookItem';
import i18n from '../../../../i18n';
import ContractListGrid from './contractListGrid/contractListGrid';
import history from '../../../history';
import './style.css'
import useScreenHeight from '../../../hooks/useScreenHeight';

const theme = getTheme();

const stackStyles: IStackStyles = { root: { padding: 0, marginBottom: 10 } };

const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};

const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };

const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const classNames = mergeStyleSets({
  wrapper: {
    // height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: { maxWidth: 400 },
  textContent: { padding: '15px 10px' },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: { width: '100%' },
  halfWidth: { width: '49%' },
  colorPicker: {
    width: '49%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  autoWidth: {
    width: 'auto',
    marginRight: 10
  },
});

// const required = (value: any) => (value ? undefined : 'Required');

const validate = (values: { company: any; gender: any; email: any }) => {
  const errors: { company: string; gender: string; email: string } = {
    company: '',
    gender: '',
    email: '',
  };
  if (!values.company) {
    errors.company = 'Company Required';
  }
  if (!values.gender) {
    errors.gender = 'Gender Required';
  }
  if (!values.email) {
    errors.email = 'Email Required';
  }
  return errors;
};

const DocumentPaneComponent = (props: {
  submit: any;
  // dropDowns: HumanResourceDropDowns;
  dropDowns: any;
  saveFormData: any;
  uid: any;
  showProgress: boolean;
  formValues: any;
  formData: any;
  isExpand: boolean;
  setForm: (form: any) => void;
  isClear: boolean;
  competencyTaxonomyList: CompetenceTaxonomy[];
  certificationTaxonomyList: CertificationTaxonomy[];
  readHumanResourceTaxonomyIdByPersonId: (personId: string) => void;
  hrRolesDropdown: any[];
  competenceSelection?: any;
  openCompetenceModal: () => void;
  competenceSelectionDetails: {};
  competenceActionButtonState: ActionButtonState;
  absenceSelection: any;
  contractSelection: any;
  absenceSelectionDetails: {};
  contractSelectionDetails: {};
  absenceActionButtonState: ActionButtonState;
  contractActionButtonState: ActionButtonState;
  deleteOrganisationSettings: (type: string) => void;
  deleteLabourHistory: (type: string) => void;
  deleteContract: (type: string) => void;
  competenceList: any;
  certificationSelection?: ISelection<IObjectWithKey> | undefined;
  openCertificationModal: () => void;
  openAbsenceModal: () => void;
  certificationSelectionDetails: {};
  certificationActionButtonState: ActionButtonState;
  certificationList: any;
  orgTaxonomyLevels: any[];
  workSchedule: WorkSchedule[];
  activityTaskSelection: any;
  onChangeStartTime: (index: number, value: string | number) => void;
  onChangeEndTime: (index: number, value: string | number) => void;
  absenceData: any;
  labourHistory: any;
  leaveTypes: any[];
  labourPerson: LabourPersonDTO;
  isDisabled?: boolean;
  contractListData: any;
  openContractListModal: () => void;

  //for certifications
  setDefaultCertificateSelection: (index: number) => void;
  selectedCertificateFromListPane: any;
  isCertification: boolean
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fontColorElement = useRef(null);
  const bgColorElement = useRef(null);

  const [cabPersonId, setCabPersonId] = useState<any>(null);
  const [member, setMember] = useState(new TeamMember());
  const [state, setstate] = useState<HumanResource | null>(null);
  const [isFontColorCalloutVisible, setFontColorCalloutVisible] = useState<any>(false);
  const [isBgColorCalloutVisible, setBgColorCalloutVisible] = useState<any>(false);
  const formContainerRef = useRef<HTMLDivElement>(null);
  const certificationsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.isCertification) {
      if (props.certificationList?.length > 0) {
        const indexOfSelectedCertificate: number = props.certificationList?.findIndex((item: any) => item.id === props.selectedCertificateFromListPane?.id)
        props.setDefaultCertificateSelection(indexOfSelectedCertificate);
      }
      // auto scroll enabled for certifications only
      const handleScroll = () => {
        if (formContainerRef.current && certificationsRef.current) {
          formContainerRef.current.scrollTo({
            top: certificationsRef.current.offsetHeight,
            behavior: 'smooth',
          });
        }
      };
    
      // Delay scrolling to ensure the rendering is complete
      const timer = setTimeout(handleScroll, 100);
      return () => clearTimeout(timer);
    }
  }, [props.isCertification, props.selectedCertificateFromListPane, props.certificationList?.length])

  const onSubmit = async (values: any) => {
    const sleep = (ms: number | undefined) =>
      new Promise((resolve) => setTimeout(resolve, ms));
    await sleep(300);
    // window.alert(JSON.stringify(values.header, 0, 2));
    // values.projectSequenceCode = getProject();
    if (!values.id) {
      values.id = props.uid;
    }
    const workSchedule = props.workSchedule.map((item) => {
      return {
        ...item,
        personId: values.personId,
      };
    });
    // values.IsDeleted = false;
    const formData = {
      ...values,
      workSchedule: workSchedule,
      cabPersonId: cabPersonId,
    };
    const { fontColorHex, bgColorHex, ...result } = formData;

    props.saveFormData(result);
  };

  const onCreateNode = (newNode: any) => {
    messageService.sendMessage({ createNewNode: newNode });
  };

  const onAddNode = (selectedLevel: any) => {
    messageService.sendMessage({ selectedNodeLevel: selectedLevel });
  };

  const getInitialFormValues = (formData: any, formValues: any) => {
    if (location.pathname.split('/').pop() === 'new' && !formData?.sequenceId) {
      return {
        role: PERSON_ROLE_WORKER_ID, // Setting Role as Worker by default
        fontColor: '#000000',
        fontColorHex: '#000000',
        bgColor: '#ffffff',
        bgColorHex: '#ffffff',
        isContactManager:false
      };

    } else if (formData && formData?.id) {
      return {
        id: formData?.id,
        sequenceId: formData?.sequenceId,
        title: formData?.title,
        personId: formData?.personId,
        azureOid: formData?.azureOid,
        organizationTaxonomyId: formData?.organizationTaxonomyId,
        personName: formData?.personName,
        role: formData?.role,
        cpcLabourItemId: formData?.cpcLabourItemId,
        cpcLabourItemTitle: formData?.cpcLabourItemTitle,
        workSchedule: formData?.workSchedule,
        fontColor: formData?.fontColor ? formData?.fontColor : '#000000',
        fontColorHex: formData?.fontColor ? formData?.fontColor : '#000000',
        bgColor: formData?.bgColor ? formData?.bgColor : '#ffffff',
        bgColorHex: formData?.bgColor ? formData?.bgColor : '#ffffff',
        isContactManager:formData?.isContactManager,
        workingOrganizationName: formData?.workingOrganizationName,
        workingOrganization: formData?.workingOrganization,
        organization: formData?.organization,
        companyName: formData?.companyName,
      };
    }
    return formValues;
  };


  const getCpcByName = async (name: RESOURCE_TYPE) => {
    const filter = {
      resourceFamilyId: null,
      resourceTypeId: "c46c3a26-39a5-42cc-b07s-89655304eh6",
      resourceNumber: name,
      resourceTitle: null,
      title: name,
      status: null,
      sorter: {
        attribute: 'resourceNumber',
        order: 'asc'
      }
    };
    if (name.length >= 2) {
      const response = await client.post(
        'CorporateProductCatalog/CpcFilter',
        filter,
      );
      return formatCpcResponse(response);
    }
  };

  const formatCpcResponse = (response: any) => {
    let options: { value: string; label: string }[] = [];
    if (response && response.data && response.data.result) {
      const data = response.data.result;

      if (data && _.isArray(data)) {
        options = data.map((item: any) => {
          return {
            value: item,
            label: item.title
          };
        });
      }
      return options;
    }
  };

  const cpcOptions = (inputValue: any) =>
    new Promise((resolve) => {
      // setTimeout(() => {
      resolve(getCpcByName(inputValue));
      // }, 1000);
    });

  useEffect(() => {
    setstate(props.formData);
  }, [props.formData]);

  const orgPromiseOptions = (inputValue: any) =>
    new Promise((resolve) => {
      resolve(searchOrganisations(inputValue));
    });

  const searchOrganisations = async (name: string) => {
    if (name) {
      const response = await client.get(CAB_COMPANY_EP + name);
      return formatOrganisationResponse(response);
    } else {
      return [];
    }
  };

  const formatOrganisationResponse = (response: any) => {
    let data: { value: string; label: string }[] = [];
    if (response && response.data && _.isArray(response.data.result)) {
      data = response.data.result.map((item: any) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
    }
    return data;
  };

  const options: IChoiceGroupOption[] = [
    { key: '1', text: t('contractor') },
    { key: '2', text: t('interim'), styles:{field: { marginLeft: "10px"}} },
  ];

  const newItem = mergeStyles({ padding: '1px' });
  const existingItem = mergeStyles({ padding: '10px' });
  const addIcon: IIconProps = { iconName: 'Add' };
  const onRenderSuggestionsItem = (props: any, itemProps: any): JSX.Element => {
    if (props.isNewItem) {
      return <div className={props.isNewItem ? newItem : existingItem} key={props.key}>
        <ActionButton iconProps={addIcon}>
          {props.text}
        </ActionButton>
      </div>;
    }
    return <div className={props.isNewItem ? newItem : existingItem} key={props.key}>
      <Persona
        {...props}
        size={1}
        hidePersonaDetails={false}
      />
    </div>;
  };

  const onFilterPersonChanged = (
    filterText: string,
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      return getPersonByName(filterText);
    }
    return [];
  };

  const getPersonByName = async (name: string) => {
    const cabFilter = new CABFilter();
    cabFilter.fullName = name;
    const response = await client.post(HR_PERSON_FILTER_EP, cabFilter);
    return formatPersonResponse(response, name);
  };

  const formatPersonResponse = (response: any, name: any) => {
    //let data:ITag[]|any = [];
    let data: { key: string; text: string; isNewItem: boolean }[] = [];
    if (response && response.data && _.isArray(response.data.result)) {
      data = response.data.result.map((item: AddressBookItem) => {
        return {
          ...item,
          isNewItem: false,
          cabPersonId: item?.person?.id,
          key: item.personCompany?.id,
          companyId: item.companyId,
          text: item.person?.fullName,
        };
      });
      let obj = data.find(
        (o: any) => o.person.fullName.toLowerCase() === name.toLowerCase(),
      );
      if (!obj) {
        data.unshift({ key: '', text: name, isNewItem: true });
      }
    } else {
      return [{ key: '', text: name, isNewItem: true }];
    }
    return data;
  };

  const limitedSearchAdditionalProps: IBasePickerSuggestionsProps = {
    resultsFooter: (props: any) => (
      <ActionButton
        className={classNames.actionButton}
        disabled={false}
        iconProps={addIcon}
        allowDisabledFocus
        onClick={(event) => {
          messageService.sendMessage({ isOpenNameModal: true });
        }}
      >
        <Label className={classNames.actionButton}>{i18n.t('new') as any}</Label>
      </ActionButton>
    ),
    showRemoveButtons: false,
    searchingText: 'Searching...',
  };

  const validateInput = (input: string): ValidationState => {
    if (input.indexOf('@') !== -1) {
      return ValidationState.valid;
    } else if (input.length > 1) {
      return ValidationState.warning;
    } else {
      return ValidationState.invalid;
    }
  };

  return (
    <div style={{ width: '100%' }} className={props.isCertification ? 'form-container' : ''} ref={formContainerRef}>
      <div className="proj-detail-block">
        <Form
          onSubmit={onSubmit}
          initialValues={getInitialFormValues(props.formData, props.formValues)}
          mutators={{
            setTaxonomyId: (args, state, utils) => {
              const field = state.fields['organizationTaxonomyId'];
              field.change(args[0]);
            },
            setAzureOid: (args, state, utils) => {
              const field = state.fields['azureOid'];
              field.change(args[0]);
            },
            setCabPersonName: (args, state, utils) => {
              const field = state.fields['cabPersonName'];
              field.change(args[0]);
            },
            setHrCPC: async (args, state, utils) => {
              const fieldTitle = state.fields['cpcLabourItemTitle'];
              const fieldId = state.fields['cpcLabourItemId'];
              if (args && args[0] && args[0]?.id) {
                fieldId.change(args[0].id);
                fieldTitle.change(args[0].title);
              } else {
                fieldId.change(null);
                fieldTitle.change(null);
              }
            },
            setFontColorHex: (args, state, utils) => {
              const field = state.fields['fontColorHex'];
              field.change(args[0]);
            },
            setBgColorHex: (args, state, utils) => {
              const field = state.fields['bgColorHex'];
              field.change(args[0]);
            },
            setWorkingOrganizationName: (args, state, utils) => {
              const field = state.fields['workingOrganizationName'];
              field.change(args[0]);
            },
            setIsContractor: (args, state, utils) => {
              const field = state.fields['organization'];
              field.change(args[0]);
            },
          }}
          // validate={ validate }
          render={({ handleSubmit, form, submitting, pristine, values }) => {
            props.submit(handleSubmit);
            props.setForm(form);

            return (
              <form onSubmit={handleSubmit} noValidate>
                <div className="document-pane-card" id="1">
                  <div className={'card-header'}>
                    <Link href="#" id="labour">
                      <Label>1. {t('labour')}</Label>
                    </Link>
                  </div>
                  {state?.sequenceId && (
                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="title"
                            component={TextFieldFF}
                            lable={t('humanResourceTitle')}
                            disabled={true}
                          />
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="sequenceId"
                            component={TextFieldFF}
                            lable={t('humanResourceId')}
                            disabled={true}
                          />
                        </div>
                      </Stack.Item>
                    </Stack>
                  )}

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field name="personId">
                          {({ input, meta }) => (
                            <div>
                              <Label required={false}>{t('name')}</Label>
                              <NormalPeoplePicker
                                selectedItems={values?.personId
                                  ? [
                                    {
                                      key: values?.personId,
                                      text: values?.personName ?? values.cabPersonName,
                                    },
                                  ]
                                  : []
                                }
                                onResolveSuggestions={onFilterPersonChanged}
                                onChange={(items: any) => {
                                  messageService.sendMessage({ isNewHR: true });
                                  input.onChange;
                                  return items ? items : [];
                                }}
                                onItemSelected={(item: any) => {
                                  if (item) {
                                    if (item.isNewItem) {
                                      const openUrl = `/address-book/new?new-person-name=${item?.text}`;
                                      window.open(openUrl);
                                    } else {
                                      input.onChange(item.key);
                                      dispatch(saveCuHrAtrr('selectedPerson', item));
                                      props.readHumanResourceTaxonomyIdByPersonId(item.key);
                                      setCabPersonId(item.cabPersonId);
                                      form.mutators.setCabPersonName(item.text);
                                      messageService.sendMessage({ labourPerson: item });
                                    }
                                  }
                                  return null;
                                }}
                                pickerSuggestionsProps={limitedSearchAdditionalProps}
                                className={'ms-PeoplePicker'}
                                key={'normal'}
                                itemLimit={1}
                                // eslint-disable-next-line react/jsx-no-bind
                                onValidateInput={validateInput}
                                removeButtonAriaLabel={'Remove'}
                                inputProps={{
                                  onBlur: () => console.log('onBlur called'),
                                  onFocus: () => console.log('onFocus called'),
                                  'aria-label': 'People Picker',
                                }}
                                onInputChange={(input: string) => {
                                  return input;
                                }}
                                resolveDelay={300}
                                disabled={state?.sequenceId as any}
                                onRenderSuggestionsItem={onRenderSuggestionsItem}
                              />
                            </div>
                          )}
                        </Field>
                        <Field
                          name="cabPersonName"
                          component={'input'}
                          type={'hidden'}
                          disabled={false}
                          hidden
                        />
                      </div>
                    </Stack.Item>
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="azureOid"
                          component={TextFieldFF}
                          lable={t('azureOid')}
                          disabled={true}
                        />
                      </div>
                    </Stack.Item>
                  </Stack>

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field name='companyName'>
                          {({ input, meta }) => (
                            <Field
                              name="companyName"
                              component={TextFieldFF}
                              lable={t('organization')}
                              disabled={true}
                            />
                          )}
                        </Field>

                      </div>
                    </Stack.Item>
                    <Stack.Item grow={2} styles={stackItemStyles}>
                      <div className={`${classNames.halfWidth}`}>
                        <Label>&nbsp;</Label>
                        <Field name="organization">
                          {({ input, meta }) => (
                            <div
                              style={{display: 'flex', flexDirection: 'row', height: 42, alignItems: 'center', gap: 8}}>
                              <Checkbox
                                label={t('contractor')}
                                disabled={false}
                                checked={values?.organization == "1"}
                                onChange={(event, value) => {
                                  if (value) {
                                    input.onChange("1");
                                    form.mutators.setIsContractor("1");
                                  } else {
                                    input.onChange("0");
                                  }
                                }}
                              />
                              <Checkbox
                                label={t('interim')}
                                disabled={false}
                                checked={values?.organization == "2"}
                                onChange={(event, value) => {
                                  if (value) {
                                    input.onChange("2");
                                    form.mutators.setIsContractor("2");
                                  } else {
                                    input.onChange("0");
                                  }
                                }}
                              />
                            </div>
                          )}
                        </Field>
                      </div>
                    </Stack.Item>
                  </Stack>

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="organizationTaxonomyId"
                          style={{ paddingTop: -20 }}
                        >
                          {({ input, meta }) => (
                            <TaxonomyField
                              label={t('organisationChart')}
                              treeData={props.dropDowns.osList}
                              selectItemId={
                                values && values.organizationTaxonomyId
                                  ? values.organizationTaxonomyId
                                  : null
                              }
                              onSelectItem={(id: string) => {
                                if (id) {
                                  if (
                                    id === props.formData.organizationTaxonomyId
                                  ) {
                                    input.onChange(null);
                                  } else {
                                    input.onChange(id);
                                  }
                                } else {
                                  input.onChange(null);
                                }
                              }}
                              treeLevel={props.orgTaxonomyLevels}
                              onFilterTaxonomyNodeChanged={() => { }}
                              onCreateNode={(newNode: any) => onCreateNode(newNode)}
                              onAddNode={(selectedLevel: any) => onAddNode(selectedLevel)}
                              labourPerson={props.labourPerson}
                              isDisabled={props.isDisabled}
                            />
                          )}
                        </Field>
                      </div>
                    </Stack.Item>
                  </Stack>

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="role"
                          component={DropDownAdaptater}
                          lable={t('role')}
                          placeholder={t('selectRole')}
                          options={props.hrRolesDropdown ? props.hrRolesDropdown : []}
                          disabled={props.isDisabled}
                        />
                      </div>
                    </Stack.Item>
                    <Stack.Item grow={2} styles={stackItemStyles}>
                      <div className={`${classNames.halfWidth}`}>
                        <Label>&nbsp;</Label>
                        <Field name="isContactManager">
                          {({ input, meta }) => (
                            <Checkbox
                              label={t('contractManager')}
                              disabled={false}
                              checked={!!values?.isContactManager}
                              onChange={(event, value) => {
                                if (value) {
                                  input.onChange(true);
                                } else {
                                  input.onChange(false);
                                }
                              }}
                            />
                          )}
                        </Field>
                      </div>
                    </Stack.Item>
                  </Stack>

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={2} styles={stackItemStyles}>
                      <div className={`${classNames.halfWidth}`}>
                        <Label>{t('workingFor')}</Label>
                        <Field name='workingOrganization'>
                          {({ input, meta }) => (
                            <SearchableDropdown
                              selectedOption={values.workingOrganizationName ? { label: values.workingOrganizationName } : null}
                              onChange={(item: any) => {
                                if (item) {
                                  const selectedItem = {
                                    key: item.value,
                                    text: item.label,
                                  };
                                  input.onChange(selectedItem.key);
                                  form.mutators.setWorkingOrganizationName(selectedItem.text);
                                } else {
                                  input.onChange(null);
                                  form.mutators.setWorkingOrganizationName(null);
                                }
                              }}
                              promiseOptions={orgPromiseOptions}
                              validationMessage={null}
                            />
                          )}
                        </Field>

                        <Field name='workingOrganizationName'>
                          {({ input, meta }) => (
                            <span hidden></span> // hidden field is to set the text of SearchableDropdown
                          )}
                        </Field>

                      </div>
                    </Stack.Item>
                  </Stack>

                  <Label style={{ marginTop: 32, marginLeft: 10 }}>1.1 {t('preferredColors')}</Label>
                  <Stack
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item>
                      <div className={`${classNames.colorPicker}`}>
                        <Label>{t('fontColor')}</Label>
                        <div className={`${classNames.fullWidth}`} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Field name="fontColor">
                            {({ input, meta }) => (
                              <div style={{ marginRight: 14 }}>
                                <div ref={fontColorElement}>
                                  <DefaultButton
                                    onClick={() => setFontColorCalloutVisible(!isFontColorCalloutVisible)}
                                    style={{ backgroundColor: `${values?.fontColor}`, minWidth: 34 }}
                                  />
                                </div>
                                <Callout
                                  gapSpace={0}
                                  target={fontColorElement.current}
                                  onDismiss={() => setFontColorCalloutVisible(false)}
                                  setInitialFocus={true}
                                  hidden={!isFontColorCalloutVisible}
                                  directionalHint={DirectionalHint.bottomRightEdge}
                                >
                                  <ColorPicker
                                    color={values?.fontColor}
                                    alphaType="none"
                                    showPreview={true}
                                    onChange={(ev: any, colorObj: IColor) => {
                                      input.onChange(colorObj?.str);
                                      form.mutators.setFontColorHex(colorObj?.str);
                                    }}
                                  />
                                </Callout>
                              </div>
                            )}
                          </Field>
                          <div style={{ maxWidth: 120 }}>
                            {/* <Field name="fontColorHex">
                              {({ input, meta }) => (
                                <TextField
                                  value={values?.fontColorHex}
                                  style={{ color: '#000' }}
                                  disabled={true}
                                />
                              )}
                            </Field> */}
                            <Field
                              name="fontColorHex"
                              component={TextFieldFF}
                              disabled={true}
                              style={{ width: 10 }}
                            />
                          </div>
                        </div>
                      </div>
                    </Stack.Item>
                    <Stack.Item>
                      <div className={`${classNames.colorPicker}`}>
                        <Label style={{ marginRight: 14 }}>{t('backgroundColor')}</Label>
                        <div className={`${classNames.fullWidth}`} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Field name="bgColor">
                            {({ input, meta }) => (
                              <div style={{ marginRight: 14 }}>
                                <div ref={bgColorElement}>
                                  <DefaultButton
                                    onClick={() => setBgColorCalloutVisible(!isBgColorCalloutVisible)}
                                    style={{ backgroundColor: `${values?.bgColor}`, minWidth: 34 }}
                                  />
                                </div>
                                <Callout
                                  gapSpace={0}
                                  target={bgColorElement.current}
                                  onDismiss={() => setBgColorCalloutVisible(false)}
                                  setInitialFocus={true}
                                  hidden={!isBgColorCalloutVisible}
                                  directionalHint={DirectionalHint.bottomRightEdge}
                                >
                                  <ColorPicker
                                    color={values?.bgColor}
                                    alphaType="none"
                                    showPreview={true}
                                    onChange={(ev: any, colorObj: IColor) => {
                                      input.onChange(colorObj?.str);
                                      form.mutators.setBgColorHex(colorObj?.str);
                                    }}
                                  />
                                </Callout>
                              </div>
                            )}
                          </Field>
                          <div style={{ maxWidth: 120 }}>
                            {/* <Field name="bgColorHex">
                              {({ input, meta }) => (
                                <TextField
                                  value={values?.bgColorHex}
                                  style={{ color: '#000' }}
                                  disabled={true}
                                />
                              )}
                            </Field> */}
                            <Field
                              name="bgColorHex"
                              component={TextFieldFF}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                    </Stack.Item>
                  </Stack>
                  {/* <pre>{JSON.stringify(values)}</pre> */}

                  <div className={'card-footer'}>
                    <Separator />
                    <Stack
                      styles={stackStyles}
                      tokens={itemAlignmentsStackTokens}
                    >
                      <Stack.Item align="end" styles={stackFooterBarItemStyles}>
                        <ActionButton
                          className={classNames.actionButton}
                          iconProps={saveIcon}
                          allowDisabledFocus
                          onClick={() => {
                            form.submit();
                          }}
                          disabled={props.isDisabled}
                        >
                          <Label className={classNames.actionButton}>
                            {t('save')}
                          </Label>
                        </ActionButton>
                      </Stack.Item>
                    </Stack>
                  </div>
                </div>

                <div className="proj-detail-block" id="2">
                  <div className="document-pane-card">
                    <div className={'card-header'}>
                      <Link href="#" id="competencies" className="marginTop">
                        <Label>2. {t('competencies')} </Label>
                      </Link>
                    </div>
                    <div className="marginTop">
                      <OrganisationGrid
                        organisationSelection={props.competenceSelection} //create a selection
                        editOrganisation={() => props.openCompetenceModal()}
                        organisationSelectionDetails={
                          props.competenceSelectionDetails
                        }
                        organisationActionButtonState={
                          props.competenceActionButtonState
                        }
                        openModel={() => props.openCompetenceModal()}
                        title={2.1 + ' ' + t('competenciesSkill')}
                        deleteDataGrid={() => {
                          props.deleteOrganisationSettings('competence');
                        }}
                        readOnly={props.isDisabled ? true : props.labourPerson?.key ? false : true}
                        listItem={
                          props.competenceList ? props.competenceList : []
                        }
                        taxonomyList={props.competencyTaxonomyList}
                        isCertificate={false}
                        // onItemInvoked={props.onItemInvoked}
                      />
                    </div>
                  </div>
                </div>

                <div className="proj-detail-block" id="3" >
                  <div className="document-pane-card">
                    <div className={'card-header'}>
                      <Link href="#" id="certifications" className="marginTop">
                        <Label>3. {t('certifications')} </Label>
                      </Link>
                    </div>
                    <div className="marginTop">
                      <OrganisationGrid
                        organisationSelection={props.certificationSelection}
                        editOrganisation={() => props.openCertificationModal()}
                        organisationSelectionDetails={
                          props.certificationSelectionDetails
                        }
                        organisationActionButtonState={
                          props.certificationActionButtonState
                        }
                        openModel={() => {
                          props.openCertificationModal();
                        }}
                        title={3.1 + ' ' + t('certificationsQualification')}
                        deleteDataGrid={() => {
                          props.deleteOrganisationSettings('certification');
                        }}
                        readOnly={props.labourPerson?.key ? false : true}
                        listItem={
                          props.certificationList ? props.certificationList : []
                        }
                        taxonomyList={props.certificationTaxonomyList}
                        isCertificate={true}
                        selectedCertificationIdFromListPane={props.selectedCertificateFromListPane}
                      />
                    </div>
                  </div>
                </div>
                        
                <div className="proj-detail-block" id="4" ref={certificationsRef}>
                  <div className="document-pane-card">
                    <div className={'card-header'}>
                      <Link href="#" id="labourHistory" className="marginTop">
                        <Label>4. {t('labourHistory')} </Label>
                      </Link>
                    </div>
                    <div className={'card-body'}>
                      <div className="marginTop">
                        <LabourHistoryGrid
                          dataList={props.labourHistory ? props.labourHistory : {}}
                          title={`4.1 ${t('pmol')}`}
                          link={'labour-history'}
                          id={'4'}
                          columns={[]}
                          isLoaded={false}
                          isExpand={false}
                          selection={undefined}
                          setOpenPOResourceModal={function (): void {
                            throw new Error('Function not implemented.');
                          }}
                          // resourceType={[]}
                          unitPrice={''}
                          labourHistoryActionButtonState={
                            props.certificationActionButtonState
                          }
                          openModel={() => { }}
                          readOnly={props.isDisabled ? true : props.labourPerson?.key ? false : true}
                          isAbsence={false}
                          deleteLabourHistory={() => {
                            props.deleteLabourHistory('');
                          }}
                        />
                      </div>

                      <div className="marginTop">
                        <Stack
                          horizontal
                          wrap
                          styles={stackStyles}
                          tokens={stackTokens}
                        >
                          <Stack.Item grow={6} styles={stackItemStyles}>
                            <div className={`${classNames.halfWidth}`}>
                              <Label required={false}>{t('preferedCpcLabourItem')}</Label>
                              <Field
                                name="cpcLabourItemId"
                              >{({ input, meta }) => (
                                <CustomDropdown
                                  reference={''}
                                  onChange={(value: any, label: string) => {
                                    if (value) {
                                      const cpc = { id: value.id, title: label };
                                      form.mutators.setHrCPC(cpc);
                                      // meta.error = null;
                                      input.onChange(value.id);
                                    } else {
                                      form.mutators.setHrCPC(null);
                                    }
                                  }}
                                  selectedOption={(values && values?.cpcLabourItemId ? {
                                    value: values?.cpcLabourItemId,
                                    label: values?.cpcLabourItemTitle
                                  } : null)}
                                  promiseOptions={cpcOptions}
                                  validationMessage={null}
                                  required={false}
                                  disabled={props.isDisabled ? true : false}
                                />
                              )}
                              </Field>
                            </div>
                            <Field
                              name="cpcLabourItemTitle"
                              component={'input'}
                              type={'hidden'}
                              disabled={false}
                              hidden
                            />
                          </Stack.Item>
                        </Stack>
                      </div>

                      <div className="marginTop">
                        <ContractListGrid
                          contractSelection={props.contractSelection} //create a selection
                          editContract={() => props.openContractListModal()}
                          contractSelectionDetails={
                            props.contractSelectionDetails
                          }
                          dataList={props.contractListData ?? []}
                          title={`4.2 ${t('contractList')}`}
                          link={'labour-history'}
                          id={'4'}
                          columns={[]}
                          isLoaded={false}
                          isExpand={false}
                          selection={undefined}
                          contractActionButtonState={
                            props.contractActionButtonState
                          }
                          openModel={() => {
                            props.openContractListModal();
                          }}
                          readOnly={props.isDisabled ? true : !props.labourPerson?.key}
                          deleteContract={() => {
                            props.deleteContract('contract');
                          }}
                        />
                      </div>

                      <div className="marginTop">
                        <LabourHistoryGrid
                          labourHistorySelection={props.absenceSelection} //create a selection
                          editLabourHistory={() => props.openAbsenceModal()}
                          labourHistorySelectionDetails={
                            props.absenceSelectionDetails
                          }
                          dataList={props.absenceData ? props.absenceData : []}
                          title={`4.3 ${t('absence')}`}
                          link={'labour-history'}
                          id={'4'}
                          columns={[]}
                          isLoaded={false}
                          isExpand={false}
                          selection={undefined}
                          setOpenPOResourceModal={function (): void {
                            throw new Error('Function not implemented.');
                          }}
                          // resourceType={[]}
                          unitPrice={''}
                          labourHistoryActionButtonState={
                            props.absenceActionButtonState
                          }
                          openModel={() => {
                            props.openAbsenceModal();
                          }}
                          readOnly={props.isDisabled ? true : props.labourPerson?.key ? false : true}
                          isAbsence={true}
                          deleteLabourHistory={() => {
                            props.deleteLabourHistory('absence');
                          }}
                        />
                      </div>
                    </div>

                    <div className={'card-footer'}>
                      <Separator />
                      <Stack
                        styles={stackStyles}
                        tokens={itemAlignmentsStackTokens}
                      >
                        <Stack.Item
                          align="end"
                          styles={stackFooterBarItemStyles}
                        >
                          <ActionButton
                            className={classNames.actionButton}
                            iconProps={saveIcon}
                            allowDisabledFocus
                            onClick={() => {
                              // alert(JSON.stringify(values.purchaseOrderType, 0, 2));
                              form.submit();
                            }}
                            disabled={(props.isDisabled)}
                          >
                            <Label className={classNames.actionButton}>
                              {t('save')}
                            </Label>
                          </ActionButton>
                        </Stack.Item>
                      </Stack>
                    </div>
                  </div>
                </div>

                <div className="proj-detail-block" id="5">
                  <div className="document-pane-card">
                    <div className={'card-header'}>
                      <Link href="#" id="workSchedule" className="marginTop">
                        <Label>5. {t('workSchedule')} </Label>
                      </Link>
                    </div>

                    <div className={'card-body'}>
                      <div className="marginTop">
                        <Stack
                          horizontal
                          wrap
                          styles={stackStyles}
                          tokens={stackTokens}
                        >
                          <div className={`${classNames.fullWidth}`}>
                            <CSWeeklyGrid
                              dataList={props.workSchedule ? props.workSchedule : []}
                              link={'cs-grid'}
                              isLoaded={false}
                              selection={props.activityTaskSelection}
                              isExpand={true}
                              disabled={props.isDisabled ? true : false}
                              onChangeStartTime={props.onChangeStartTime}
                              onChangeEndTime={props.onChangeEndTime}
                              typeId={props.formData.workSchedule?.id}
                            />
                          </div>
                        </Stack>
                      </div>
                    </div>

                    <div className={'card-footer'}>
                      <Stack
                        styles={stackStyles}
                        tokens={itemAlignmentsStackTokens}
                      >
                        <Stack.Item
                          align="end"
                          styles={stackFooterBarItemStyles}
                        >
                          <ActionButton
                            className={classNames.actionButton}
                            iconProps={saveIcon}
                            allowDisabledFocus
                            onClick={() => {
                              // alert(JSON.stringify(values.workSchedule, 0, 2));
                              form.submit();
                            }}
                            disabled={(props.isDisabled)}
                          >
                            <Label className={classNames.actionButton}>
                              {t('save')}
                            </Label>
                          </ActionButton>
                        </Stack.Item>
                      </Stack>
                    </div>
                  </div>
                </div>

                {
                  props.formData.history && props.formData.history.createdDate && (
                    <div className="proj-detail-block" id="6">
                      <div className="document-pane-card">
                        <div className="marginTop marginBottom">
                          <Link href="#" id="history">
                            <Label>6. {t('history')} </Label>
                          </Link>

                          <HistoryBar
                            createdByUser={
                              props.formData.history.createdBy
                                ? props.formData.history.createdBy
                                : ''
                            }
                            updatedByUser={
                              props.formData.history.modifiedBy
                                ? props.formData.history.modifiedBy
                                : ''
                            }
                            createdDateTime={
                              props.formData.history.createdDate
                                ? props.formData.history.createdDate
                                : ''
                            }
                            updatedDateTime={
                              props.formData.history.modifiedDate
                                ? props.formData.history.modifiedDate
                                : ''
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )
                }

                 {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
              </form>
            );
          }}
        />
      </div>
    </div >
  );
};
export default DocumentPaneComponent;
