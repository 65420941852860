import { Label, Link } from '@fluentui/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ExtraWork as ExtraWorkTypes,
  Journal,
  PMOL_TYPE,
  PMOLService,
  ProjectMolecule,
  ProjectMoleculeValidationMessage,
  Resource,
} from '../../../types/projectMolecule';
import HistoryBar from '../../../shared/historyBar/historyBar';
import ProjectHeader from '../../../shared/projectHeader/projectHeader';
import { BorResources } from '../../../types/billOfResources';
import { ActionButtonState } from '../../../types/corporateProductCatalogue';
import {
  Competence,
  Instruction,
  Quality,
  ReadRisk,
} from '../../../types/projectBreakdownStructure';
import { LocationAddress, Position } from '../../../types/projectDefinition';
import { Signature } from '../handshake/modal/component';
import ProjectMoleculerHeader from '../project/projectMoleculerHeader';
import ServiceUI from '../service/componant';
import CbcGrid from '../cbc/CbcGrid';
import StartHandshake from './formComponents/StartHandshake';
import PlannedWork from './formComponents/PlannedWork';
import ExtraWorkComponent from './formComponents/ExtraWork';
import ProjectMoleculeJournal from './formComponents/ProjectMoleculeJournal';
import StopHandshake from './formComponents/StopHandshake';
import History from './formComponents/History';

export const DocumentPaneComponent = (props: {
  loadApproveMsg: string;
  projectHeader: any;
  dropDownOptions: any;
  isEdit: boolean;
  formData: ProjectMolecule;
  extraWork: ExtraWorkTypes;
  saveFormData: () => void;
  PMOLSaveJournal: () => void;
  handleFormChange: (projectMolecule: ProjectMolecule, save: boolean) => void;
  handleExtraChange: (extra: any[]) => void;
  handleJournalChange: (journal: any[]) => void;
  handleHandshakeChange: (handshakeDoc: any[]) => void;
  validationMessages: ProjectMoleculeValidationMessage;
  borResources: BorResources;

  handleRiskDelete: () => void;
  qualitySelection: any;
  //qualitySelectionDetails: {};
  qualityActionButtonState: ActionButtonState;
  selectedResourceFamily: string;
  onChangeResourceFamily: (treeData: any) => void;
  openRiskModal: () => void;
  riskSelection: any;
  riskActionButtonState: ActionButtonState;
  savePicturesOfTheInstallation: (files: FileList | null) => void;
  resourceTypes: [];
  resourceFamily: any;
  cpcBasicUnitOfMeasure: [];
  cpcPressureClass: [];
  cpcMaterial: [];
  cpcBrand: [];
  languageCodes: [];
  cpcUnitOfSizeMeasure: [];

  resetSelection: () => void;
  submitCpc: boolean;
  riskList: ReadRisk[];
  openQualityModal: () => void;
  qualityList: Quality[];
  handleQualityDelete: () => void;
  materials: Resource[];
  openMaterialModal: () => void;
  materialActionButtonState: ActionButtonState;
  materialSelection: any;
  tools: Resource[];
  toolsActionButtonState: ActionButtonState;
  openToolsModal: () => void;
  toolSelection: any;
  handleMaterialDelete: () => void;
  handleToolsDelete: () => void;
  materialLoading: boolean;
  toolsLoading: boolean;
  labours: Resource[];
  labourActionButtonState: ActionButtonState;
  openLabourModal: () => void;
  labourSelection: any;
  labourLoading: boolean;
  handleLabourDelete: () => void;
  openConsumableModal: () => void;
  consumableSelection: any;
  consumableActionButtonState: ActionButtonState;
  consumables: Resource[];
  consumalbeLoading: boolean;
  handleConsumableDelete: () => void;
  openTechInstuctionModal: () => void;
  onFilterPersonChanged: any;
  techInstructionsLoading: boolean;
  techInstructions: Instruction[];
  techInstructionSelection: any;
  techInstructionActionButtonState: ActionButtonState;
  onQualityFilterPersonChanged: any;
  competenceList: Competence[];
  competenceSelection: any;
  competenceActionButtonState: ActionButtonState;
  openCompetenceModal: () => void;
  handleCompetenceDelete: () => void;
  handleDTechInstructionDelete: () => void;
  openSaftyInstuctionModal: () => void;
  saftyInstructionsLoading: boolean,
  saftyInstructions: Instruction[];
  saftyInstructionSelection: any,
  saftyInstructionActionButtonState: ActionButtonState,
  handleSaftyInstructionDelete: () => void;
  //
  openEnvInstuctionModal: () => void;
  envInstructionsLoading: boolean,
  envInstructions: Instruction[];
  envInstructionSelection: any,
  envInstructionActionButtonState: ActionButtonState,
  handleEnvInstructionDelete: () => void;
  //
  openHelthInstuctionModal: () => void;
  helthInstructionsLoading: boolean,
  helthInstructions: Instruction[];
  helthInstructionSelection: any;
  helthInstructionActionButtonState: ActionButtonState;
  handleHelthInstructionDelete: () => void;
  setJournal: (feild: any, value: any) => void;
  setJournalImage: (type: string, response: any) => void;
  position: Position;
  setLocationAddress: (position: Position, address: LocationAddress) => void;
  openMapModal: () => void;
  address: string;
  readOnly: boolean;
  openSignatureModal: () => void;
  signatures: Signature[];
  handshakeDocs: any[];
  readHandShakeStatus: boolean;
  saveHandshakeDocuments: (data: {
    pmolId: string,
    docLinks: string[]
  }) => void;
  journal: Journal;
  readExtraWork: boolean;
  borStatusDropdowns: { key: string, text: string }[];
  isLoading: boolean;
  isDisabled: boolean;
  onVisibitiltyChangeStartHandShake: (isVisible: boolean) => void;
  onVisibilityChangePlannedWorks: (isVisible: boolean) => void;
  isRisksLoading: boolean;
  pmolType: PMOL_TYPE;
  onChangeService: (prop: string, value: string) => void;
  service: PMOLService
}) => {
  const { t } = useTranslation();
  
  useEffect(() => {
    props.resetSelection();
  }, [props.formData.id]);

  useEffect(() => {
    if (props.submitCpc) {
      props.saveFormData();
    }
  }, [props.submitCpc]);

  return (
    <div style={{ width: '100%' }} id={'ProjectBreakdownForm'}>

      {/* APPROVE PMOL ERROR MESSAGE

      <div style={{ position: "sticky", top: 0, zIndex: 100 }}>
       <CustomAlert
          showMsg={isCustomAlertDisplayed}
          msg={t(props?.loadApproveMsg)}
          msgType={'error'}
          handleCustomAlert={() => {
            setIsCustomAlertDisplayed(false)
            dispatch(savePMOLStateAttr('loadApproveMsg', ''));
          }}
        />
      </div> */}

      <div className="proj-detail-block">
        <ProjectHeader projectDetails={props.projectHeader}/>
      </div>

      <div className="proj-detail-block" id="1">
        <ProjectMoleculerHeader
          dropDownOptions={props.dropDownOptions}
          isEdit={props.isEdit}
          formData={props.formData}
          saveFormData={() => props.saveFormData()}
          handleFormChange={(projectMolecule: ProjectMolecule, save: boolean) =>
            props.handleFormChange(projectMolecule, save)
          }
          validationMessages={props.validationMessages}
          borStatusDropdowns={props.borStatusDropdowns}
          isLoading={props.isLoading}
          isDisabled={props.isDisabled}
          pmolType={props.pmolType}
        />
      </div>

      <div className="proj-detail-block" id="2">
        <StartHandshake {...props}/>
      </div>
      
      <div className="proj-detail-block" id="23">
        <CbcGrid
          formData={props.formData}
          saveFormData={() => props.saveFormData()}
          handleFormChange={(projectMolecule: ProjectMolecule, save: boolean) =>
            props.handleFormChange(projectMolecule, save)
          }
          readOnly={props.readOnly}
        />
      </div>

      {props.pmolType === PMOL_TYPE.REGULAR &&
        <div className="proj-detail-block" id="7">
          <PlannedWork {...props}/>
        </div>
      }

      {props.pmolType === PMOL_TYPE.REGULAR &&
        <div className="proj-detail-block" id="12">
          <ExtraWorkComponent {...props}/>
        </div>
      }

      {props.pmolType === PMOL_TYPE.SUB &&
        <div className="proj-detail-block" id="30">
          <ServiceUI
            service={props.service}
            pmol={props.formData}
            onChangeService={(prop: string, val: any) => props.onChangeService(prop, val)}
            saveFormData={() => props.saveFormData()}
            productTitle={props.formData.productTitle ? props.formData.productTitle : ''}
            projectTitle={props.formData?.projectDefinition?.title ? props.formData?.projectDefinition?.title : ''}
            cpcBasicUnitOfMeasure={props.cpcBasicUnitOfMeasure}
            validationMessages={props.validationMessages}
          />
        </div>
      }

      <div className="proj-detail-block" id="18">
        <ProjectMoleculeJournal {...props}/>
      </div>

      <div className="proj-detail-block" id="20">
        <StopHandshake {...props}/>
      </div>

      {props.isEdit &&
        props.formData.historyLog &&
          props.formData.historyLog.createdByUser && (
            <div className="proj-detail-block" id="22">
              <History 
                createdByUser={props.formData?.historyLog?.createdByUser}
                updatedByUser={props.formData?.historyLog?.updatedByUser}
                createdDateTime={props.formData?.historyLog?.createdDateTime}
                updatedDateTime={props.formData?.historyLog?.updatedDateTime}
              />
            </div>
      )}
    </div>

  );
};

export default DocumentPaneComponent
