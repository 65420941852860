import * as React from 'react';
import DataGrid from '../../../shared/dataGrid/component';
import { RESOURCE_TYPE } from '../../../types/purchaseOrder';
import {
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  Selection,
  Stack, 
  // TextField,
} from '@fluentui/react';
import { ActionButtonState } from '../../../types/corporateProductCatalogue';
import i18n from '../../../../i18n';
import {
  getPBSContractor,
  readCbcContractor,
  readCbcToPmol,
  removeCbcToPmol,
} from '../../../reducers/pmolReducer';
import CbcModal from './CbcModal';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectMolecule } from '../../../types/projectMolecule';
import ClearableDropdown from '../../../shared/clearableDropdown/clearableDropdown';
// import { store } from '../../../../index';
// import { property } from 'lodash';


const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

const stackStyles: IStackStyles = {
  root: {
    padding: 0,
    marginBottom: 10,
  },
};

const classNames = mergeStyleSets({
  halfWidth: { width: '50%' }
});
const CbcGrid = (props: {
  readOnly: boolean,
  formData: ProjectMolecule;
  saveFormData: () => void;
  handleFormChange: (
    projectMolecule: ProjectMolecule,
    save: boolean
  ) => void;
}) => {
  const [selectionDetails, setSelectionDetails] = React.useState<any>(null);
  const [contractor, setContractor] = React.useState<any>(null);
  const [isOpenModal, setIsOpenModal] = React.useState<any>(false);
  const [cbcLoading, setCbcLoading] = React.useState<any>(false);
  const dispatch = useDispatch();

  const { pmolCbcList, reFetchCbc, isLoadingCbc, cbcContractor, isChange }: any = useSelector(
    (state: any) => state.pmol
  );

  useEffect(() => {
    dispatch(readCbcContractor())
  }, []);


  useEffect(() => {
    if (reFetchCbc) {
      getPmolCbsList();
    }
  }, [reFetchCbc]);

  useEffect(() => {
    if (props?.formData?.id) {
      getPmolCbsList();
    }
    // if (props?.formData?.pmolLotId) {
    //   setContractor({ key: props?.formData?.pmolLotId, text: props?.formData?.pmolLotId });
    // } else {
    //   setContractor(null);
    // }
    // if (itemNotShowcased) {
    //   setContractor({
    //     key: pmolCbcList?.[0]?.lotId,
    //     text: pmolCbcList?.[0]?.lotId
    //   })
    // } else {
    //   setContractor(null)
    // }
  }, [props?.formData?.id]);

  useEffect(() => {
    const itemNotShowcased = pmolCbcList?.some((item: any) => item.isLotInfoShowcased === false)
    if (!itemNotShowcased) {
      setContractor({
        key: pmolCbcList?.[0]?.lotId,
        text: pmolCbcList?.[0]?.lotId
      })
    } else {
      setContractor(null)
    }
  }, [pmolCbcList])

  useEffect(() => {
    if (props?.formData?.productId) {
      loadPBSContractor();
    }
  }, [props?.formData?.productId]);

  const openCbcModal = () => {
    setIsOpenModal(!isOpenModal);
  }
  const handleCbcDelete = () => {
    let ids = selectionDetails?.map((item: any) => {
      return item?.id
    })
    dispatch(removeCbcToPmol(ids))
    setCbcLoading(true);
    setTimeout(() => {
      // getPbsCbsList();
    }, 4000)
  }

  const _gridSelection = new Selection({
    onSelectionChanged: () => {
      setSelectionDetails(_gridSelection.getSelection());

    }
  });

  const getPmolCbsList = () => {
    dispatch(readCbcToPmol(props?.formData?.id))
    setCbcLoading(false);
  }
  const loadPBSContractor = () => {
    Promise.all([
      dispatch(getPBSContractor(props?.formData?.productId))
    ]).then((response: any) => {
      if (response[0]?.payload?.data?.result) {
        let data = response[0]?.payload?.data?.result;
        props.formData.pmolLotId = data?.pbsLotId
        props.handleFormChange(
          props.formData,
          true
        );
        // console.log({ response });
        setContractor({ key: data?.pbsLotId, text: data?.pbsLotId });
      }
    });
  }

  const cbcActionButtonState = () => {
    const selectionCount = selectionDetails?.length;
    let disabled: ActionButtonState;
    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        // add: !props?.formData?.productId,
        add: false,
        edit: true,
        remove: true,
      };
    }
    return disabled;
  }

  const cbcColumns = [
    {
      key: 'column1',
      name: i18n.t('cbc'),
      fieldName: 'title',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true
    },
    {
      key: 'column2',
      name: i18n.t('originalQuantity'),
      fieldName: 'cbcQuantity',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true
    },
    {
      key: 'column3',
      name: i18n.t('plannedQuantity'),
      fieldName: 'quantity',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true
    },
    {
      key: 'column4',
      name: i18n.t('consumedQuantity'),
      fieldName: 'consumedQuantity',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true
    },
    {
      key: 'column5',
      name: i18n.t('unit'),
      fieldName: 'unit',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true
    }
  ];

  const onSaveSuccess = () => {
    setCbcLoading(true);
    setTimeout(() => {
      // getPmolCbsList();
    }, 4000)
  }
  const sortByProperty = (dataArray: [], propertyName: string) => {
    return dataArray.sort((a, b) => {
      const propA = a[propertyName];
      const propB = b[propertyName];

      // You can add more complex comparison logic here if needed
      if (propA < propB) {
        return -1;
      }
      if (propA > propB) {
        return 1;
      }
      return 0;
    });
  };
  
  

  return (
    <>
      <div className={'document-pane-card'}>
        <div className={'card-header'}>
          <Link href="#" id="pmol-cbc">
            <Label>3. {i18n.t('cbc') as string}</Label>
          </Link>
        </div>
        <div className={'card-body'}>
          <Link href="#" id="pmol-cbc-3-1">
            <Label style={{ marginTop: 10 }}>{'3.1 ' + i18n.t('contractor')}</Label>
          </Link>
          <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
            <Stack.Item grow={6} styles={stackItemStyles}>
              <div className={`${classNames.halfWidth}`}>
              {/* {contractor?.key !==null && contractor?.key !=="" && contractor?.key !==undefined ?
                <TextField
                  label={i18n.t('contractor')}
                  value={(contractor && contractor?.key) ? contractor.key : ''}
                  disabled={true}
                />:''} */}
                <ClearableDropdown
                  required={false}
                  placeholder={i18n.t('contractor')}
                  label={''}
                  options={cbcContractor?cbcContractor:[]}
                  onChange={(event, value) => {
                    // setContractor(value)
                    if (value) {
                      setContractor(value)
                      props.formData.pmolLotId=value?.key
                    } else {
                      setContractor(null)
                      props.formData.pmolLotId=null
                    }
                    props.handleFormChange(
                      props.formData,
                      true
                    );
                  }}
                  selectedKey={contractor ? contractor.key : ''}
                  disabled={props.readOnly}
                />
              </div>
            </Stack.Item>
            <Stack.Item grow={6} styles={stackItemStyles}>

            </Stack.Item>
          </Stack>
          <DataGrid
            dataList={pmolCbcList ? sortByProperty(pmolCbcList, 'articleNo') : []}
            editDataGrid={() => openCbcModal()}
            openModel={() => {
              openCbcModal();
            }}
            selection={_gridSelection}
            actionButtonState={cbcActionButtonState()}
            // title={ i18n.t('cbc')}
            title={'3.2 ' + i18n.t('cbc')}
            deleteDataGrid={() => {
              handleCbcDelete();
            }}
            readOnly={props.readOnly}
            columns={cbcColumns}
            isLoaded={cbcLoading || isLoadingCbc}
            type={RESOURCE_TYPE.CBC}
            isClickable={true}
          />
        </div>
      </div>
      <CbcModal 
        formData={selectionDetails ? selectionDetails[0] : {}} 
        isOpen={isOpenModal} 
        contractor={contractor}
        setContractor={setContractor}
        cbcContractor={cbcContractor} 
        openModal={openCbcModal} 
        projectMolecule={props?.formData} 
        onSaveSuccess={onSaveSuccess} 
        pmolCbcList={pmolCbcList ? pmolCbcList : []} 
        actionButtonState={cbcActionButtonState()} 
      />
    </>
  );

};

export default CbcGrid
