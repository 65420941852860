import * as React from 'react';
import { ProjectMolecule } from '../../../types/projectMolecule';
import i18n from '../../../../i18n';
import {
  ContextualMenu,
  FontWeights, getTheme,
  IconButton, IDragOptions, IIconProps,
  IStackItemStyles, IStackStyles,
  IStackTokens,
  Label, mergeStyleSets,
  Modal,
  PrimaryButton,
  Stack,
  TextField,
} from '@fluentui/react';
// import CustomDropdown from '../../../shared/customDropdown/customDropdown';
import { useDispatch } from 'react-redux';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { useEffect, useState } from 'react';
import { saveCbcToPmol, readCbcContractor } from '../../../reducers/pmolReducer';
import client from '../../../api';
import _ from 'lodash';
import { uPrinceTheme } from '../../../../theme';
import ClearableDropdown from '../../../shared/clearableDropdown/clearableDropdown';
// import { store } from '../../../../index';
const theme = getTheme();

const stackTokens: IStackTokens = {
  // childrenGap: 10,
  padding: 10,
};
// const stackItemStyles1: IStackItemStyles = {
//   root: {
//     display: 'flex',
//     height: 50,
//     marginRight: 10,
//     width: '100%', // Set the width to 100%
//   },
// };
const stackItemStyles: IStackItemStyles = {

  root: {
    display: 'flex',
    height: 50,
    width: 0,
    // width: '50%',
    flexGrow: 1,
    marginRight: 10,
  },
};

const stackStyles: IStackStyles = {
  root: {
    padding: 0,
    height: 40,
    // marginBottom: 10,
  },
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const addIconWhite: IIconProps = {
  iconName: 'Add',
  styles: addWhiteIconButtonStyles,
};
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    overflowY: 'hidden',
    minWidth: '32vw',
    minHeight: screen.width > 1280 ? '42vh' : '60vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});
const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const modalStyles = {
  main: {
    maxWidth: '1200px', // Set the maximum width of the modal
    width: '200%',      // You can also use percentage values
  },
};

const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};
interface Item {
  id: string;
  lotId: string;
  companyId: string;
  title: string;
  articleNo: string;
  quantity: string;
  consumedQuantity: string;
  unit: string;
  pmolId?: string,
  originalQuantity: any
}
const CbcModal = (props: { 
  isOpen: boolean, 
  openModal: any, 
  contractor: any,
  setContractor: any,
  cbcContractor: any, 
  projectMolecule: ProjectMolecule, 
  onSaveSuccess: any, 
  formData: any, 
  pmolCbcList: any, 
  actionButtonState: any 
}) => {
  const dispatch = useDispatch();
  const [isDraggable] = useBoolean(false);
  const titleId = useId('title');
  const [cbc, setCbc]: any = React.useState<any>(null);
  const [quantityValidationMsg, setQuantityValidationMsg]: any = React.useState<any>("");
  const [quantity, setQuantity]: any = React.useState<any>('0');
  // const [cbcQuantity, setCbcQuantity]: any = React.useState<any>('0');
  // const [consumedQuantity, setConsumedQuantity]: any = React.useState<any>('0');
  const [disableSave, setDisableSave] = useState(true);
  const [focus, setFocus] = useState(1);
  const [data, setData] = useState<Item[]>([]);
  const [updatedData, setUpdatedData] = useState<Item[]>([])
  
  useEffect(() => {
    if (props?.formData?.id) {
      const filteredData = props?.pmolCbcList?.filter((item: any) => item.id === props?.formData?.id);
      setCbc({ key: filteredData[0].lotId, text: filteredData[0].lotId });
      setData(filteredData)
    } else {
      // setCbc(null);
      setData([])
    }
  }, [props?.formData, props?.pmolCbcList]);

  useEffect(() => {
    setUpdatedData(data)
  }, [data])

  const onModalClose = () => {
    props.openModal();
    props.projectMolecule.pmolLotId = props.contractor.key
  }

  useEffect(() => {
    if (cbc && quantity) {
      setDisableSave(false)

    } else {
      setDisableSave(true)
    }
  }, [cbc, quantity]);

  // const getId = (item: any) => {
  //   if (item.articleNo === props.formData?.articleNo) {
  //     return props.formData?.id
  //   } else {
  //     return null
  //   }
  // }

  const saveForm = async () => {
    const filteredData = updatedData
      .filter(item => item.pmolId)
      .map(item => ({
        id: props?.formData?.id !== null && props?.formData?.id !== undefined && props?.formData?.id !== '' ? props?.formData?.id : null,
        // id: getId(item),
        articleNo: item.articleNo,
        quantity: item.quantity,
        consumedQuantity: item.consumedQuantity,
        pmolId: item.pmolId,
        lotId: item.lotId,
        // originalQuantity: item.originalQuantity,
        IsLotInfoShowcased: props.contractor ? true : false
      }));
    dispatch(saveCbcToPmol(filteredData))
  }

  const onSaveCbc = () => {
    Promise.all([
      saveForm()
    ]).then(() => {
      setCbc(null);
      setQuantity('0');
      props?.onSaveSuccess()
      onModalClose();
    });
  }
  const onSaveAndNewCbc = () => {
    setFocus(0);
    Promise.all([
      saveForm()
    ]).then(() => {
      props?.onSaveSuccess()
      setCbc(null);
      setData([])
      setQuantity('0');
      setFocus(1);
    });
  }

  useEffect(() => {
    if (props?.contractor !== null) {
      setCbc(props.contractor)
      getProductByName(props.contractor.key)
      setDisableSave(false)
    } 
    // else {
    //   setData([])
    //   setDisableSave(true)
    // }
  }, [props?.contractor])
  
  const getProductByName = async (cbc: string) => {
    const payload = { title: null, lotId: cbc };
    const response = await client.post('/Contractor/GetAwardedContractorLotData', payload);
      const filtercbc = response.data.result.map((item:any) => ({
        ...item,
        quantity: ""
      }));
     
      if (cbc !== null && cbc !== '' && cbc !== undefined) {
        const newDataTitles = props?.pmolCbcList?.map((item: any) => item.title);

        const filteredFilterCbc = filtercbc.filter((item: any) => {
          return !newDataTitles.includes(item.title);
        });
        setData(filteredFilterCbc)
      } else {
        setData([])
      }
  };
  // const cbcdata: Item[] = data
  // const updatedData: Item[] = data
  
  return (
    <>
      <Modal
        titleAriaId={titleId}
        isOpen={props.isOpen}
        onDismiss={() => {
          onModalClose();
        }}
        scrollableContentClassName="body"
        isBlocking={true}
        dragOptions={isDraggable ? dragOptions : undefined}
        styles={modalStyles}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>{i18n.t("cbc") as string}</span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              onModalClose();
            }}
          />
        </div>
        <div className={contentStyles.body}>
          <div>
            <div style={{ marginBottom: '40px' }}>
              <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div style={{ width: '100%' }}>
                    <Label style={{ marginTop: 10 }}>{i18n.t('contractor') as string}</Label>
                    {focus && <ClearableDropdown
                      required={false}
                      placeholder={i18n.t('contractor')}
                      label={''}
                      options={props?.cbcContractor}
                      onChange={(event, value) => {
                        if (value) {
                          setCbc(value)
                          props.setContractor(null)
                          getProductByName(value?.key)
                          props.projectMolecule.pmolLotId = value?.key
                        } else {
                          setCbc(null)
                          props.projectMolecule.pmolLotId = null
                          setData([])
                        }
                      }}
                      selectedKey={cbc ? cbc?.key: ''}
                      // selectedKey={cbc ? cbc?.key : ""}
                      // disabled={props.contractor !== null ? true : false}
                    />}
                  </div>

                </Stack.Item>
              </Stack>
            </div>
            <div style={{ margin: '9px' }}>
              <div className="ms-Grid-row" style={{ display: 'flex', marginBottom: '12px' }}>
                <div className="ms-Grid-col ms-sm12 ms-md8 ms-lg8">
                  <Label style={{ marginTop: 10 }}>{i18n.t('CBC Items') as string}</Label>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md1 ms-lg1">
                  <Label style={{ marginTop: 10 }}>{i18n.t('CBC Quantity') as string}</Label>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md1 ms-lg1">
                  <Label style={{ marginTop: 10 }}>{i18n.t('Planned Quantity') as string}</Label>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md1 ms-lg1">
                  <Label style={{ marginTop: 10 }}>{i18n.t('Consumed Quantity') as string}</Label>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md1 ms-lg1">
                  <Label style={{ marginTop: 10 }}>{i18n.t('mou') as string}</Label>
                </div>
              </div>
              {updatedData?.map(item => (
                <div key={item?.id} className="ms-Grid-row" style={{ display: 'flex', marginBottom: '12px' }}>
                  <div className="ms-Grid-col ms-sm12 ms-md8 ms-lg8">
                    <TextField
                      style={{ color: 'black' }}
                      value={item.title}
                      errorMessage={quantityValidationMsg}
                      type={'text'}
                      disabled={true}
                      readOnly
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md1 ms-lg1">
                    <TextField
                      style={{ color: 'black' }}
                      value={'0'}
                      errorMessage={quantityValidationMsg}
                      type={'number'}
                      disabled={true}
                      readOnly
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md1 ms-lg1">
                    <TextField
                      style={{ color: 'black' }}
                      value={ item.quantity }
                      // value={item.quantity =='1'
                      //   ? ''
                      //   : item.quantity
                      // }
                      errorMessage={quantityValidationMsg}
                      type={'number'}
                      step={1}
                      min={0}
                      onChange={(event, newValue) => {
                        if (newValue === null || newValue === undefined) return; // Prevent setting null or undefined values
                        const updatedItem = { ...item };
                        updatedItem.quantity = newValue;
                        if (item.quantity !== newValue) {
                          updatedItem.pmolId = props?.projectMolecule.id ?? undefined;
                        }
                        const updated = updatedData.map((dataItem) =>
                          dataItem.id === updatedItem.id ? updatedItem : dataItem
                        );
                        setData(updated);
                      }}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md1 ms-lg1">
                    <TextField
                      style={{ color: 'black' }}
                      value={item.consumedQuantity
                        ? item.consumedQuantity.toString()
                        : '0'
                      }
                      errorMessage={""}
                      type={'number'}
                      step={1}
                      min={0}
                      onChange={(event, newValue) => {
                        if (newValue === null || newValue === undefined) return; // Prevent setting null or undefined values
                        const updateItem = { ...item };

                        // Update the quantity property of the item object
                        updateItem.consumedQuantity = newValue;

                        // Conditionally update the pbsId property only when quantity is changed
                        if (item.consumedQuantity !== newValue) {
                          updateItem.pmolId = props?.projectMolecule.id ?? undefined;
                        }

                        // Update the state of the item in your data array
                        const updated = updatedData?.map((dataItem) =>
                          dataItem.id === updateItem.id ? updateItem : dataItem
                        ); // Update only the modified item in the data array

                        setData(updated); // Update the state of the data array
                      }}

                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md1 ms-lg1">
                    <TextField
                      style={{ color: 'black' }}
                      value={item.unit
                        ? item.unit
                        : ''
                      }
                      errorMessage={quantityValidationMsg}
                      type={'text'}
                      disabled={true}
                      readOnly
                    />
                  </div>
                </div>
              ))}
            </div>
            <div>

            </div>
          </div>
        </div>
        <div className={contentStyles.footer}>
          <PrimaryButton
            iconProps={addIconWhite}
            text={i18n.t('save')}
            disabled={disableSave}
            style={{ marginTop: 20, marginBottom: 30, marginRight: 20 }}
            onClick={() => {
              onSaveCbc()
            }}
          />

          <PrimaryButton
            iconProps={addIconWhite}
            text={i18n.t('saveAndNew')}
            disabled={disableSave}
            style={{ marginTop: 20, marginBottom: 30 }}
            onClick={() => {
              onSaveAndNewCbc();
            }}
          />
        </div>
      </Modal>
    </>
  );

};

export default CbcModal
