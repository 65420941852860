import { Icon, Label, Link } from '@fluentui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactVisibilitySensor from 'react-visibility-sensor'
import AddressLocationComponent from '../../../../shared/location/address/component'
import { LocationAddress, Position } from '../../../../types/projectDefinition'
import InstructionDataGrid from '../../instructions/dataGrid/component'
import { Instruction, PbsInstructionLink, Quality, ReadRisk } from '../../../../types/projectBreakdownStructure'
import { ActionButtonState } from '../../../../types/corporateProductCatalogue'
import { uPrinceTheme } from '../../../../../theme'
import RiskGrid from '../../risk/component'
import QualityGrid from '../../quality/component'

const StartHandshake = (props: {
  onVisibitiltyChangeStartHandShake: (isVisible: boolean) => void;
  setLocationAddress: (position: Position, address: LocationAddress) => void;
  position: Position;
  openMapModal: () => void;
  address: string;
  readOnly: boolean;
  //
  openTechInstuctionModal: () => void;
  techInstructions: Instruction[];
  techInstructionSelection: any;
  techInstructionActionButtonState: ActionButtonState;
  handleDTechInstructionDelete: () => void;
  techInstructionsLoading: boolean;
  //
  openSaftyInstuctionModal: () => void;
  saftyInstructionsLoading: boolean,
  saftyInstructions: Instruction[];
  saftyInstructionSelection: any,
  saftyInstructionActionButtonState: ActionButtonState,
  handleSaftyInstructionDelete: () => void;
  //
  openEnvInstuctionModal: () => void;
  envInstructionsLoading: boolean,
  envInstructions: Instruction[];
  envInstructionSelection: any,
  envInstructionActionButtonState: ActionButtonState,
  handleEnvInstructionDelete: () => void;
  //
  openHelthInstuctionModal: () => void;
  helthInstructionsLoading: boolean,
  helthInstructions: Instruction[];
  helthInstructionSelection: any;
  helthInstructionActionButtonState: ActionButtonState;
  handleHelthInstructionDelete: () => void;
  //
  riskList: ReadRisk[];
  openRiskModal: () => void;
  riskSelection: any;
  riskActionButtonState: ActionButtonState;
  handleRiskDelete: () => void;
  isRisksLoading: boolean;
  //
  openQualityModal: () => void;
  qualityList: Quality[];
  handleQualityDelete: () => void;
  qualitySelection: any;
  qualityActionButtonState: ActionButtonState;
}) => {
  const {t} = useTranslation();

  const riskColumns = [
    {
      key: 'column1',
      name: t('ID'),
      fieldName: 'sequenceCode',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('title'),
      fieldName: 'title',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column3',
      name: t('owner'),
      fieldName: 'owner',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column4',
      name: t('type'),
      fieldName: 'type',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column5',
      name: t('status'),
      fieldName: 'status',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
  ];

  const InstructionColumns = [
    {
      key: 'column1',
      name: t('title'),
      fieldName: 'title',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('family'),
      fieldName: 'pbsInstructionFamilyName',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: 'column3',
      name: t('links'),
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: Instruction) => {
        let array: PbsInstructionLink[] = item && item.pbsInstructionLink && Array.isArray(item.pbsInstructionLink) ? item.pbsInstructionLink : [];
        return (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gridGap: '2px',
              gridAutoRows: 'minMax(10px, auto)',
            }}
          >
            {array.map((link) => (

              <div key={link.id}>
                <Icon
                  style={{ cursor: 'pointer', color: uPrinceTheme.palette.themePrimary }}
                  iconName={link.type!!}
                  key={link.id}
                  onClick={(event) => {
                    event.preventDefault();
                    window.open(link.link!!);
                  }}
                />
              </div>
            ))}
          </div>
        );
      },
    },
  ];

  const qualtyColumns = [
    {
      key: 'column1',
      name: t('qualityCriteria'),
      fieldName: 'criteria',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: Quality) => {
        let cleanText = item.criteria ? item.criteria!!.replace(/<\/?[^>]+(>|$)/g, '') : '-';
        return <div>
          {cleanText}
        </div>;
      },
    },
    {
      key: 'column2',
      name: t('qualityTolerance'),
      fieldName: 'tolerance',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: Quality) => {
        let cleanText = item.tolerance ? item.tolerance!!.replace(/<\/?[^>]+(>|$)/g, '') : '-';
        return <div>
          {cleanText}
        </div>;
      },
    },
    {
      key: 'column3',
      name: t('qualityMethod'),
      fieldName: 'method',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: Quality) => {
        let cleanText = item.method ? item.method!!.replace(/<\/?[^>]+(>|$)/g, '') : '-';
        return <div>
          {cleanText}
        </div>;
      },
    },
    {
      key: 'column4',
      name: t('qualitySkillRequired'),
      fieldName: 'skills',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: Quality) => {
        let cleanText = item.skills ? item.skills!!.replace(/<\/?[^>]+(>|$)/g, '') : '-';
        return <div>
          {cleanText}
        </div>;
      },
    },
  ];

  return (
    <div className="document-pane-card">
      <div className={'card-header'}>
        <Link href="#" id="pmol-start-handshake">
          <Label>2. {t('startHandshake')} </Label>
        </Link>
      </div>

      <ReactVisibilitySensor scrollCheck onChange={props.onVisibitiltyChangeStartHandShake}>
        <div className={'card-header'}>
          <div className="proj-detail-block" id="3">
            <Link href="#" id="start-handshake-id-2-1">
              <Label>2.1 {t('pMOLAddress')} </Label>
            </Link>
          </div>
        </div>
      </ReactVisibilitySensor>

      <div style={{ zIndex: 10000000, marginTop: '8px' }}>
        <AddressLocationComponent
          setLocationAddress={(address) => {
            props.setLocationAddress(address.position, address);
          }}
          position={props.position}
          openMapModal={() => props.openMapModal()}
          address={props.address}
          disabled={props.readOnly}
          lable={t('pMOLCoordinates')}
          addressLable={t('pMOLAddress')}
        />
      </div>

      <div className={'card-header'}>
        <div className="proj-detail-block" id="4">
          <Link href="#" id="start-handshake-id-2-2">
            <Label>2.2 {t('instructions')} </Label>
          </Link>
        </div>
      </div>

      <div className={'card-body'}>
        <div>
          <ReactVisibilitySensor scrollCheck onChange={props.onVisibitiltyChangeStartHandShake}>
            <InstructionDataGrid
              dataList={props.techInstructions}
              editDataGrid={() => props.openTechInstuctionModal()}
              openModel={() => {
                props.openTechInstuctionModal();
              }}
              selection={props.techInstructionSelection}
              actionButtonState={props.techInstructionActionButtonState}
              title={'2.2.1 ' + t('technicalInstructions')}
              deleteDataGrid={() => {
                props.handleDTechInstructionDelete();
              }}
              readOnly={props.readOnly}
              columns={InstructionColumns}
              isLoaded={props.techInstructionsLoading}
            />
          </ReactVisibilitySensor>

          <ReactVisibilitySensor scrollCheck onChange={props.onVisibitiltyChangeStartHandShake}>
            <InstructionDataGrid
              dataList={props.saftyInstructions}
              editDataGrid={() => props.openSaftyInstuctionModal()}
              openModel={() => {
                props.openSaftyInstuctionModal();
              }}
              selection={props.saftyInstructionSelection}
              actionButtonState={props.saftyInstructionActionButtonState}
              title={'2.2.2 ' + t('saftyInstructions')}
              deleteDataGrid={() => {
                props.handleSaftyInstructionDelete();
              }}
              readOnly={props.readOnly}
              columns={InstructionColumns}
              isLoaded={props.saftyInstructionsLoading}
            />
          </ReactVisibilitySensor>

          <ReactVisibilitySensor scrollCheck onChange={props.onVisibitiltyChangeStartHandShake}>
            <InstructionDataGrid
              dataList={props.envInstructions}
              editDataGrid={() => props.openEnvInstuctionModal()}
              openModel={() => {
                props.openEnvInstuctionModal();
              }}
              selection={props.envInstructionSelection}
              actionButtonState={props.envInstructionActionButtonState}
              title={'2.2.3 ' + t('environmentalInstructions')}
              deleteDataGrid={() => {
                props.handleEnvInstructionDelete();
              }}
              readOnly={props.readOnly}
              columns={InstructionColumns}
              isLoaded={props.envInstructionsLoading}
            />
          </ReactVisibilitySensor>

          <ReactVisibilitySensor scrollCheck onChange={props.onVisibitiltyChangeStartHandShake}>
            <InstructionDataGrid
              dataList={props.helthInstructions}
              editDataGrid={() => props.openHelthInstuctionModal()}
              openModel={() => {
                props.openHelthInstuctionModal();
              }}
              selection={props.helthInstructionSelection}
              actionButtonState={props.helthInstructionActionButtonState}
              title={'2.2.4 ' + t('healthInstructions')}
              deleteDataGrid={() => {
                props.handleHelthInstructionDelete();
              }}
              readOnly={props.readOnly}
              columns={InstructionColumns}
              isLoaded={props.helthInstructionsLoading}
            />
          </ReactVisibilitySensor>
        </div>
        <div className="proj-detail-block" id="5">
          <div id="start-handshake-id-2-3">
            <ReactVisibilitySensor scrollCheck onChange={props.onVisibitiltyChangeStartHandShake}>
              <RiskGrid
                dataList={props.riskList ? props.riskList : []}
                editDataGrid={() => props.openRiskModal()}
                openModel={() => {
                  props.openRiskModal();
                }}
                selection={props.riskSelection}
                actionButtonState={props.riskActionButtonState}
                title={t('risk')}
                deleteDataGrid={() => {
                  props.handleRiskDelete();
                }}
                readOnly={props.readOnly}
                columns={riskColumns}
                isLoaded={props.isRisksLoading}
              />
            </ReactVisibilitySensor>
          </div>
        </div>
        <div className="proj-detail-block" id="6">
          <div id="start-handshake-id-2-4">
            <ReactVisibilitySensor scrollCheck onChange={props.onVisibitiltyChangeStartHandShake}>
              <QualityGrid
                dataList={props.qualityList}
                editDataGrid={() => props.openQualityModal()}
                openModel={() => {
                  props.openQualityModal();
                }}
                selection={props.qualitySelection}
                actionButtonState={props.qualityActionButtonState}
                title={t('quality')}
                deleteDataGrid={() => {
                  props.handleQualityDelete();
                }}
                readOnly={props.readOnly}
                columns={qualtyColumns}
              />
            </ReactVisibilitySensor>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StartHandshake